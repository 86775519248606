import gsap from 'gsap';
import { useEffect, useRef } from 'react';
import classnames from 'utils/classnames';

interface CarouselDragIconProps {
  className?: string;
  isDraging: boolean;
}

const CarouselDragIcon: React.FC<CarouselDragIconProps> = (props) => {
  const isActive = props.isDraging;

  const backgroundCircleRef = useRef(null);
  const strokeCircleRef = useRef(null);
  const textLightRef = useRef(null);

  const arrowLeftRef = useRef(null);
  const arrowRightRef = useRef(null);

  useEffect(() => {
    const active = isActive;
    const background = backgroundCircleRef.current;
    const stroke = strokeCircleRef.current;
    const textLight = textLightRef.current;

    const arrowLeft = arrowLeftRef.current;
    const arrowRight = arrowRightRef.current;

    if (active) {
      gsap.fromTo(
        background,
        { opacity: 1, scale: 1, transformOrigin: 'center center' },
        {
          opacity: 0,
          scale: 1.2,
          duration: 0.3,
          transformOrigin: 'center center',
          ease: 'power3.inOut',
        }
      );
      gsap.fromTo(
        stroke,
        { scale: 0, opacity: 0, transformOrigin: 'center center' },
        {
          opacity: 1,
          scale: 1,
          duration: 0.3,
          transformOrigin: 'center center',
          ease: 'power3.inOut',
        }
      );
      gsap.to(textLight, {
        opacity: 0,
        duration: 0.3,
        transformOrigin: 'center center',
        ease: 'power3.inOut',
      });

      gsap.fromTo(
        arrowLeft,
        { opacity: 0, x: 10, transformOrigin: 'center center' },
        {
          x: 0,
          opacity: 1,
          duration: 0.3,
          transformOrigin: 'center center',
          ease: 'power3.inOut',
        }
      );

      gsap.fromTo(
        arrowRight,
        { opacity: 0, x: -10, transformOrigin: 'center center' },
        {
          x: 0,
          opacity: 1,
          duration: 0.3,
          transformOrigin: 'center center',
          ease: 'power3.inOut',
        }
      );
    } else {
      gsap.fromTo(
        background,
        { scale: 0.2, opacity: 0, transformOrigin: 'center center' },
        {
          opacity: 1,
          scale: 1,
          duration: 0.3,
          transformOrigin: 'center center',
          ease: 'power3.inOut',
        }
      );
      gsap.to(stroke, {
        opacity: 0,
        scale: 1.2,
        duration: 0.3,
        transformOrigin: 'center center',
        ease: 'power3.inOut',
      });
      gsap.to(textLight, {
        opacity: 1,
        duration: 0.3,
        transformOrigin: 'center center',
        ease: 'power3.inOut',
      });

      gsap.to(arrowLeft, {
        x: -20,
        opacity: 0,
        duration: 0.3,
        transformOrigin: 'center center',
        ease: 'power3.inOut',
      });
      gsap.to(arrowRight, {
        x: 20,
        opacity: 0,
        duration: 0.3,
        transformOrigin: 'center center',
        ease: 'power3.inOut',
      });
    }

    return () => {
      gsap.killTweensOf([background, stroke, textLight, arrowLeft, arrowRight]);
    };
  }, [isActive]);

  return (
    <svg
      width="120"
      height="64"
      viewBox="0 0 120 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classnames('overflow-visible', props.className)}
    >
      <rect
        x="29"
        y="1"
        width="62"
        height="62"
        rx="31"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        ref={backgroundCircleRef}
      />
      <rect
        x="29"
        y="1"
        width="62"
        height="62"
        rx="31"
        stroke="currentColor"
        strokeWidth="2"
        ref={strokeCircleRef}
      />
      <g ref={textLightRef} fill="white">
        <path d="M48.5096 28.1109H46V35.4683H48.5096C50.6463 35.4683 52.1883 33.886 52.1883 31.7997C52.1883 29.7134 50.6463 28.1109 48.5096 28.1109ZM47.5723 29.6428H48.4995C49.7392 29.6428 50.6161 30.5802 50.6161 31.7997C50.6161 33.0192 49.7392 33.9364 48.4995 33.9364H47.5723V29.6428Z" />
        <path d="M57.5641 35.4683H59.4085L57.8564 33.0696C58.7232 32.6463 59.2674 31.7795 59.2674 30.7313C59.2674 29.2195 58.1487 28.1109 56.5764 28.1109H53.4016V35.4683H54.9739V33.3417H56.1934L57.5641 35.4683ZM54.9739 29.6428H56.6167C57.2517 29.6428 57.6951 30.0964 57.6951 30.7313C57.6951 31.3663 57.2517 31.8198 56.6066 31.8198H54.9739V29.6428Z" />
        <path d="M64.7784 35.4683H66.3608L63.9822 28.1109H62.3696L59.9809 35.4683H61.5331L61.8455 34.5008H64.466L64.7784 35.4683ZM62.3293 32.989L63.1557 30.429L63.9822 32.989H62.3293Z" />
        <path d="M69.8879 31.578V33.0293H72.2564C71.9238 33.6743 71.2787 34.0775 70.3817 34.0775C69.0513 34.0775 68.1946 33.1301 68.1946 31.7896C68.1946 30.5298 69.1219 29.5521 70.422 29.5521C71.309 29.5521 72.0246 30.0057 72.3874 30.6809L73.7178 29.8343C73.0728 28.7257 71.8835 28 70.422 28C68.2148 28 66.6224 29.663 66.6224 31.7896C66.6224 33.9767 68.1543 35.5792 70.3616 35.5792C72.5487 35.5792 74 34.017 74 31.8803V31.578H69.8879Z" />
      </g>
      <g ref={arrowLeftRef} className="opacity-0">
        <path d="M19 26L13 32L19 38" stroke="currentColor" strokeWidth="2" />
      </g>
      <g ref={arrowRightRef} className="opacity-0">
        <path d="M101 26L107 32L101 38" stroke="currentColor" strokeWidth="2" />
      </g>
    </svg>
  );
};

export default CarouselDragIcon;
