import AlarmIcon from 'components/icons/AlarmIcon';
import RegistrationIcon from 'components/icons/RegistrationIcon';
import { RecipeData } from 'lib/contentful/types';

interface RecipeCardTitleProps {
  recipe: RecipeData;
}

const RecipeCardTitle: React.FC<RecipeCardTitleProps> = (props) => {
  const {
    recipe: { title, cookingTime, serves },
  } = props;

  return (
    <div className="flex h-[132px] flex-col justify-between px-4 py-5">
      <div className="h-14 pb-8">
        <span className="text-lg font-medium leading-7">{title}</span>
      </div>
      <div className="pl-2">
        <span>
          <div className="flex items-center gap-4 text-base font-normal lg:gap-6">
            <div className="inline-flex items-center">
              <AlarmIcon className="mr-2" />
              {cookingTime}
            </div>
            <div className="inline-flex items-center">
              <RegistrationIcon className="mr-2" />
              {serves}
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};

export default RecipeCardTitle;
