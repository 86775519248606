interface RegistrationIconProps {
  className?: string;
}

const RegistrationIcon: React.FC<RegistrationIconProps> = (props) => {
  return (
    <>
      <svg
        width="24"
        height="25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m9 17.5 3-2.94a9.34 9.34 0 0 0-1-.06c-2.67 0-8 1.34-8 4v2h9l-3-3Zm2-5c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4Zm4.47 8.5L12 17.5l1.4-1.41 2.07 2.08L20.6 13l1.4 1.41L15.47 21Z"
          className="fill-current"
        />
      </svg>
    </>
  );
};

export default RegistrationIcon;
