export const isTouch = () => window && window.matchMedia('(pointer: coarse)').matches;

export const isSafari = () => {
  const ua = navigator && navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0;
};

export const isApple = () => {
  return [
    'iPhone',
    'iPad',
    'iPod',
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
  ].includes(navigator?.platform);
};
