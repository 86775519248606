import AlertOutlineIcon from '../icons/AlertOutlineIcon';

const OutOfStockBadge: React.FC = () => {
  return (
    <div className="relative flex flex-nowrap items-center justify-center gap-x-1 rounded-full bg-sf-blue-700 px-3 py-2 text-xs font-medium">
      <div>
        <AlertOutlineIcon />
      </div>
      <div className={'ml-1 mt-0.5 text-white'}>Out of Stock</div>
    </div>
  );
};

export default OutOfStockBadge;
