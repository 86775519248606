interface IconProps {
  className?: string;
}

const AlertOutlineIcon: React.FC<IconProps> = (props) => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <circle cx="9" cy="9" r="8" stroke="white" />
        <path d="M9 10.4549L9 4.63671" stroke="white" />
        <path d="M9 13.3651L9 11.9105" stroke="white" />
      </svg>
    </>
  );
};

export default AlertOutlineIcon;
