export const getMousePosition = (e) => {
  const event = e;

  let x = 0;
  let y = 0;
  if (
    event.type == 'touchstart' ||
    event.type == 'touchmove' ||
    event.type == 'touchend' ||
    event.type == 'touchcancel'
  ) {
    const evt = typeof event.originalEvent === 'undefined' ? event : event.originalEvent;
    const touch = evt.touches[0] || evt.changedTouches[0];
    x = touch.pageX;
    y = touch.pageY;
  } else if (
    event.type == 'mousedown' ||
    event.type == 'mouseup' ||
    event.type == 'mousemove' ||
    event.type == 'mouseover' ||
    event.type == 'mouseout' ||
    event.type == 'mouseenter' ||
    event.type == 'mouseleave' ||
    event.type == 'pointerup'
  ) {
    x = event.clientX;
    y = event.clientY;
  }

  return { x, y };
};
