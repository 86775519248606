interface AlarmIconProps {
  className?: string;
}

const AlarmIcon: React.FC<AlarmIconProps> = (props) => {
  return (
    <>
      <svg
        width="24"
        height="25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          className="fill-current"
          d="m22 6.2-4.6-3.9-1.3 1.5 4.6 3.9L22 6.2ZM7.9 3.9 6.6 2.4 2 6.2l1.3 1.5 4.6-3.8Zm4.6 4.6H11v6l4.7 2.9.8-1.2-4-2.4V8.5Zm-.5-4c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9Zm0 16c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7Z"
        />
      </svg>
    </>
  );
};

export default AlarmIcon;
