import { BigCommerceImageData } from 'lib/bigcommerce/types';
import classnames from 'utils/classnames';

type BcImageProps = BigCommerceImageData & {
  className?: string;
  layout?: 'fixed' | 'responsive';
  objectFit?: `fill` | `contain` | `cover` | `none` | `scale-down`;
};

const BCImage: React.FC<BcImageProps> = (props) => {
  return (
    <picture
      className={classnames('', {
        'absolute inset-0': props.layout === 'fixed',
      })}
    >
      <source media="(min-width: 640px) and (max-width: 767px)" srcSet={props.img640px}></source>
      <source media="(min-width: 768px) and (max-width: 1023px)" srcSet={props.img960px}></source>
      <source media="(min-width: 1024px) and (max-width: 2559px)" srcSet={props.img1280px}></source>
      <source media="(min-width: 2560px)" srcSet={props.urlOriginal}></source>
      <img
        alt={props.altText}
        src={props.img320px}
        className={classnames('', props.className, {
          'absolute left-0 right-0 h-full w-full': props.layout === 'fixed',
          'object-contain': props.objectFit === 'contain',
          'object-fill': props.objectFit === 'fill',
          'object-cover': props.objectFit === 'cover',
          'object-none': props.objectFit === 'none',
          'object-scale-down': props.objectFit === 'scale-down',
        })}
      />
    </picture>
  );
};

export default BCImage;
