import gsap from 'gsap';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'utils/classnames';
import { isTouch } from 'utils/device';
import { getMousePosition } from 'utils/mouse';
import CarouselDragIcon from './CarouselDragIcon';

interface CarouselSwiperProps {
  length: number;
  className?: string;
  children?: React.ReactNode;
}

const CarouselSwiper: React.FC<CarouselSwiperProps> = (props) => {
  const router = useRouter();

  const { length } = props;

  const containerRef = useRef(null);
  const scrollableRef = useRef(null);
  const iconRef = useRef(null);
  const dragRef = useRef(null);

  const [isDraging, setIsDraging] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    const scrollable = scrollableRef.current;
    const icon = iconRef.current;
    const drag = dragRef.current;

    const getNumberOfVisibleCards = (): number => {
      return window.matchMedia('(min-width: 768px)').matches ? 3 : 1;
    };

    const swiperStatus = {
      latestGridSize: getNumberOfVisibleCards(),
      animationRequest: null,
      isMoveEnabled: false,
      moveCount: 0,
    };

    const currentScrollablePosition = { x: 0, y: 0 };
    const currentMousePosition = { x: 0, y: 0 };
    const initialMousePosition = { x: 0, y: 0 };

    const getPositionX = (): number => {
      const containerBounds = container.getBoundingClientRect();

      const moveMultiplyer = window.matchMedia('(min-width: 768px)').matches ? 2 : 3;

      const positionX = Math.max(
        Math.min(
          currentScrollablePosition.x +
            (currentMousePosition.x - initialMousePosition.x) * moveMultiplyer,
          0
        ),
        -(scrollable.scrollWidth - containerBounds.width)
      );

      return positionX;
    };

    const onMouseEnter = () => {
      gsap.killTweensOf(drag);
      gsap.to(drag, { opacity: 1, duration: 0.3, ease: 'power3.out' });
    };

    const onMouseLeave = () => {
      gsap.killTweensOf(drag);
      gsap.set(drag, { opacity: 0 });
    };

    const onMouseMove = (event: MouseEvent) => {
      const mousePosition = getMousePosition(event);

      currentMousePosition.x = mousePosition.x;
      currentMousePosition.y = mousePosition.y;
    };

    const onContainerMouseMove = () => {
      if (swiperStatus.moveCount > 3) {
        if (!swiperStatus.isMoveEnabled) {
          gsap.set(scrollable, { pointerEvents: 'none' });
        }
        swiperStatus.isMoveEnabled = true;
      }
      swiperStatus.moveCount += 1;
    };

    const onContainerMouseUp = () => {
      setIsDraging(false);

      document.removeEventListener('mouseup', onContainerMouseUp);
      document.removeEventListener('mousemove', onContainerMouseMove);

      document.removeEventListener('touchend', onContainerMouseUp);
      document.removeEventListener('touchmove', onContainerMouseMove);

      currentScrollablePosition.x = getPositionX();

      if (swiperStatus.isMoveEnabled) {
        const numberOfVisibleCards = getNumberOfVisibleCards();

        swiperStatus.isMoveEnabled = false;

        const scrollableWidth = scrollable.scrollWidth - container.getBoundingClientRect().width;

        const index = Math.round(
          (Math.abs(currentScrollablePosition.x) * (length - numberOfVisibleCards)) /
            scrollableWidth
        );

        const percent =
          numberOfVisibleCards == 1 ? index * 90 : index * (100 / numberOfVisibleCards);

        swiperStatus.latestGridSize = numberOfVisibleCards;

        gsap.killTweensOf(scrollable);
        gsap.to(scrollable, {
          x: `-${percent}%`,
          duration: 0.4,
          ease: 'power2.out',
          onComplete: () => {
            gsap.set(scrollable, { pointerEvents: 'auto' });
          },
        });
      }
    };

    const onResize = () => {
      if (swiperStatus.latestGridSize != getNumberOfVisibleCards()) {
        gsap.killTweensOf(scrollable);
        gsap.set(scrollable, {
          x: `0`,
        });

        currentScrollablePosition.x = 0;
      }
    };

    const onContainerMouseDown = (event: MouseEvent) => {
      setIsDraging(true);

      const mousePosition = getMousePosition(event);

      initialMousePosition.x = mousePosition.x;
      initialMousePosition.y = mousePosition.y;

      currentMousePosition.x = mousePosition.x;
      currentMousePosition.y = mousePosition.y;

      currentScrollablePosition.x =
        scrollable.getBoundingClientRect().x - container.getBoundingClientRect().x;

      swiperStatus.moveCount = 0;

      if (isTouch()) {
        document.addEventListener('touchend', onContainerMouseUp);
        document.addEventListener('touchmove', onContainerMouseMove);
      } else {
        document.addEventListener('mouseup', onContainerMouseUp);
        document.addEventListener('mousemove', onContainerMouseMove);
      }
    };

    const renderAnimation = () => {
      const containerBounds = container.getBoundingClientRect();

      gsap.to(icon, {
        x: currentMousePosition.x - containerBounds.x,
        y: currentMousePosition.y - containerBounds.y,
        duration: 0.3,
      });

      if (swiperStatus.isMoveEnabled) {
        gsap.to(scrollable, {
          x: getPositionX(),
          duration: 0.3,
        });
      }

      swiperStatus.animationRequest = window.requestAnimationFrame(renderAnimation);
    };

    if (isTouch()) {
      container.addEventListener('touchstart', onContainerMouseDown);
      document.addEventListener('touchmove', onMouseMove);
    } else {
      container.addEventListener('mousedown', onContainerMouseDown);
      document.addEventListener('mousemove', onMouseMove);
    }

    container.addEventListener('mouseenter', onMouseEnter);
    container.addEventListener('mouseleave', onMouseLeave);

    swiperStatus.animationRequest = window.requestAnimationFrame(renderAnimation);

    window.addEventListener('resize', onResize);

    gsap.killTweensOf(scrollable);
    gsap.set(scrollable, { x: '0%' });

    return () => {
      container.removeEventListener('mousedown', onContainerMouseDown);
      container.removeEventListener('mouseenter', onMouseEnter);
      container.removeEventListener('mouseleave', onMouseLeave);

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('touchmove', onMouseMove);

      document.removeEventListener('mouseup', onContainerMouseUp);
      document.removeEventListener('mousemove', onContainerMouseMove);

      document.removeEventListener('touchend', onContainerMouseUp);
      document.removeEventListener('touchmove', onContainerMouseMove);

      window.removeEventListener('resize', onResize);

      cancelAnimationFrame(swiperStatus.animationRequest);
    };
  }, [length, router.query]);

  return (
    <div ref={containerRef} className="block-carousel relative cursor-pointer">
      <div ref={scrollableRef} className="gpu relative">
        <div className="relative flex max-w-none flex-row flex-nowrap">{props.children}</div>
      </div>
      <div
        ref={iconRef}
        className={classnames('pointer-events-none absolute left-0 top-0', {
          hidden: length <= 3,
        })}
      >
        <div ref={dragRef} className="-translate-x-1/2 -translate-y-1/2 transform opacity-0">
          <CarouselDragIcon isDraging={isDraging} />
        </div>
      </div>
    </div>
  );
};

export default CarouselSwiper;
