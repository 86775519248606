import React, { useRef } from 'react';
import Img from 'components/Img';
import SubscriptionBadge from 'components/ui/SubscriptionBadge';
import { PRODUCT_FALLBACK_IMAGE } from 'config/const';
import gsap from 'gsap';
import { BcImageFragment } from 'lib/bigcommerce';
import { ContenfulImageAssetData } from 'lib/contentful/types';
import NextLink from 'next/link';
import classnames from 'utils/classnames';
import BCImage from '../BcImage';
import OutOfStockBadge from '../ui/OutOfStockBadge';
interface ImageCardProps {
  href: string;
  image?: BcImageFragment | ContenfulImageAssetData;
  type: 'carousel' | 'listing' | 'related';
  badge?: '' | 'subscription';
  isInStock?: boolean;
  sizes?: string;
  children?: React.ReactNode;
}

const ImageCard: React.FC<ImageCardProps> = (props) => {
  const { href, image, type, badge, sizes, isInStock = true } = props;

  const currentImage = image;

  const backgroundRef = useRef(null);

  const onMouseEnter = () => {
    const background = backgroundRef.current;
    gsap.to(background, { opacity: 1, duration: 0.3, ease: 'power3.out' });
  };

  const onMouseLeave = () => {
    const background = backgroundRef.current;
    gsap.to(background, { opacity: 0.5, duration: 0.3, ease: 'power3.out' });
  };

  return (
    <>
      <div
        className={classnames('relative', {
          'min-w-[90%] pr-4 md:min-w-[33.33%]': type === 'carousel',
          'w-full': type === 'listing' || type === 'related',
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <NextLink href={`${href}`} passHref>
          <a className="no-drag">
            <div className="group relative h-full overflow-hidden rounded-md">
              <div ref={backgroundRef} className="absolute inset-0 opacity-50">
                <div className="absolute inset-0 overflow-hidden rounded-md border-2 border-sf-blue-700 opacity-[0.15]"></div>
              </div>
              <div className="relative flex h-full flex-col">
                <div
                  className={classnames('relative w-full overflow-hidden bg-slate-300', {
                    'min-h-[282px] lg:min-h-[432px]': type === 'carousel',
                    'min-h-[328px] lg:min-h-[353px]': type === 'listing',
                    'aspect-h-1 aspect-w-1': type === 'related',
                  })}
                >
                  <div
                    className={classnames('absolute inset-0 overflow-hidden', {
                      'transform duration-1000   group-hover:scale-105 group-hover:ease-out':
                        type === 'listing',
                    })}
                  >
                    {currentImage && currentImage.__typename == 'Image' && (
                      <>
                        <BCImage {...currentImage} className="" layout="fixed" objectFit="cover" />
                      </>
                    )}
                    {currentImage && currentImage.__typename == 'Asset' && (
                      <>
                        <Img sizes={sizes} {...currentImage} layout="fill" objectFit="cover" />
                      </>
                    )}
                    {!currentImage && (
                      <>
                        <div className="absolute inset-0">
                          <div
                            className="absolute inset-0 bg-cover bg-center"
                            style={{
                              backgroundImage: `url(${PRODUCT_FALLBACK_IMAGE})`,
                            }}
                          ></div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="absolute inset-0">
                    <div className="relative">
                      {badge === 'subscription' && (
                        <div className="absolute left-0 top-0 px-4 py-4 xl:hidden 2xl:block">
                          <SubscriptionBadge type="normal" />
                        </div>
                      )}
                      {badge === 'subscription' && (
                        <div className="absolute left-0 top-0 hidden px-4 py-4 xl:block 2xl:hidden">
                          <SubscriptionBadge type="small" />
                        </div>
                      )}
                      {!isInStock && (
                        <div className="absolute right-0 top-0 px-4 py-4">
                          <OutOfStockBadge />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-1">{props.children}</div>
              </div>
            </div>
          </a>
        </NextLink>
      </div>
    </>
  );
};

export default ImageCard;
